import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";
import store from "@/store";

export const printActions = {
  async selectedPlantTonaj() {
    const selectedId = store.state.app.selectedPlantId;
    if (store.state.plantsModule.plantData.length > 0) {
      return (
        store.state.plantsModule.plantData.filter((x) => x.id == selectedId)[0]
          ?.tonaj ?? store.state.plantsModule.plantData[0]?.tonaj
      );
    } else return 1000;
  },
  async printFormulaService(ctx, { url, formula, formulaid }) {
    var ext = "pdf";
    switch (formula.rasyon[0].raporcikti) {
      case "PDF":
        ext = "pdf";
        break;
      case "EXCEL":
        ext = "xlsx";
        break;
      case "WORD":
        ext = "docx";
        break;
      default:
        ext = "pdf";
        break;
    }
    const print = (downloadedFile) => {
      var downloadLink = document.createElement("a");
      downloadLink.target = "_blank";
      downloadLink.download = formula.rasyon[0].rasyonname + "." + ext;
      var blob = new Blob([downloadedFile.data], {
        type: "application/" + ext,
      });
      var URL = window.URL || window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      downloadLink.href = downloadUrl;

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
      return blob;
    };
    return await axios
      .post(url, formula, {
        headers: {
          id: formulaid,
          plantid: formula.plantid,
          "response-type": "arraybuffer",
        },
        responseType: "blob",
      })
      .then((response) => print(response));
  },

  async printProdFormula(ctx, formula) {
    let hammaddeler = formula.hammaddeler;
    let besinmaddeleri = formula.besinmaddeleri;
    let _newformula = {};
    const plantTonaj = await ctx.dispatch("selectedPlantTonaj");
    _newformula = { ...formula };
    _newformula.hammaddeler = hammaddeler.map((x) => {
      delete x.id;
      return {
        id: x.id,
        ikey: x.ikey,
        code: x.kod,
        ingredientname: x.ingredientname,
        karisim: (x.karisim * plantTonaj) / 1000,
        fiyat: x.fiyat,
      };
    });
    var b = {};
    _newformula.besinmaddeleri = besinmaddeleri.map((x) => {
      return {
        id: x.id,
        code: x.kod,
        nkey: x.nkey,
        nutrientname: x.nutrientname,
        birimadi: x.birimadi,
        deger: x.deger,
      };
    });
    var isSuccess = await ctx.dispatch("printFormulaService", {
      url: "/Printing/PrintProductionRation",
      formula: _newformula,
      formulaid: formula.rasyon[0].id,
    });
    if (typeof isSuccess == "object") {
      ctx.dispatch("toast", {
        msg: "successful",
        type: "saved",
        tur: "formula",
      });
      return isSuccess;
    } else {
      ctx.dispatch("toast", {
        msg: "unsuccessful",
        type: "notSaved",
        tur: "formula",
      });
    }
  },

  async printProdArchiveFormula(ctx, formula) {
    let hammaddeler = formula.hammaddeler;
    let besinmaddeleri = formula.besinmaddeleri;
    let _newformula = {};
    const plantTonaj = await ctx.dispatch("selectedPlantTonaj");
    _newformula = { ...formula };
    _newformula.hammaddeler = hammaddeler.map((x) => {
      delete x.id;
      return {
        id: x.id,
        ikey: x.ikey,
        code: x.kod,
        ingredientname: x.ingredientname,
        karisim: (x.karisim * plantTonaj) / 1000,
        fiyat: x.fiyat,
      };
    });
    _newformula.besinmaddeleri = besinmaddeleri.map((x) => {
      return {
        id: x.id,
        code: x.kod,
        nkey: x.nkey,
        nutrientname: x.besinmaddesi,
        birimadi: x.birimadi,
        deger: x.deger,
      };
    });
    var isSuccess = await ctx.dispatch("printFormulaService", {
      url: "/Printing/PrintProductionArsivRation",
      formula: _newformula,
      formulaid: formula.rasyon[0].id,
    });
    if (typeof isSuccess == "object") {
      ctx.dispatch("toast", {
        msg: "successful",
        type: "saved",
        tur: "formula",
      });
      return isSuccess;
    } else {
      ctx.dispatch("toast", {
        msg: "unsuccessful",
        type: "notSaved",
        tur: "formula",
      });
    }
  },

  async printIngredientNeedReport(ctx, formula) {
    let hammaddeler = formula.hammaddeler;
    let formuller = formula.formuller;
    let _newformula = {};
    const plantTonaj = await ctx.dispatch("selectedPlantTonaj");
    _newformula = { ...formula };

    _newformula.hammaddeler = hammaddeler
      .filter((x) => x.amount != 0)
      .map((x) => {
        delete x.id;
        return {
          Aciklama: x.ingredientname,
          Deger: x.amount?.toString() ?? "",
        };
      });
    _newformula.formuller = formuller
      .filter((x) => x.amount != 0)
      .map((x) => {
        return {
          Aciklama: x.rationname,
          Deger: x.amount?.toString() ?? "",
        };
      });
    _newformula.rasyon = formula.raporbilgileri;
    var isSuccess = await ctx.dispatch("printFormulaService", {
      url: "/Printing/PrintIngredientNeedReport",
      formula: _newformula,
      formulaid: formula.raporbilgileri[0].id,
    });
    if (typeof isSuccess == "object") {
      ctx.dispatch("toast", {
        msg: "successful",
        type: "saved",
        tur: "formula",
      });
      return isSuccess;
    } else {
      ctx.dispatch("toast", {
        msg: "unsuccessful",
        type: "notSaved",
        tur: "formula",
      });
    }
  },

  async printRation(ctx, formula) {
    let hammaddeler = formula.hammaddeler;
    let besinmaddeleri = formula.besinmaddeleri;
    let _newformula = {};
    const plantTonaj = await ctx.dispatch("selectedPlantTonaj");
    _newformula = { ...formula };

    _newformula.hammaddeler = hammaddeler.map((x) => {
      delete x.id;
      return {
        ikey: x.ikey,
        karisim: parseFloat((x.karisim * 1000) / 1000) + 0.0000000000001,
        min: +parseFloat(x.min.toFixed(100)) + 0.0000000000001,
        max: +parseFloat(x.max.toFixed(100)) + 0.0000000000001,
        yemfiyati: (
          +parseFloat(x.price.toFixed(100)) + 0.0000000000001
        ).toString(),
        standart: 0.0000000000001,
        mevcut: 0.0000000000001,
        aktif: x.aktif,
        stok: x.stok,
        hammaddeadi: x.ingredientname,
        code: x.kod,
      };
    });
    _newformula.besinmaddeleri = besinmaddeleri.map((x) => {
      return {
        nkey: x.nkey,
        deger: (+parseFloat(x.deger.toFixed(100)) + 0.0000000000001).toString(),
        min: (+parseFloat(x.min.toFixed(100)) + 0.0000000000001).toString(),
        max: (+parseFloat(x.max.toFixed(100)) + 0.0000000000001).toString(),
        aktif: x.aktif,
        code: x.kod,
        nutrientname: x.nutrientname,
        birim: x.birim,
      };
    });
    var isSuccess = await ctx.dispatch("printFormulaService", {
      url: "/Printing/PrintTmr",
      formula: _newformula,
      formulaid: formula.rasyon[0].id,
    });
    if (typeof isSuccess == "object") {
      ctx.dispatch("toast", {
        msg: "successful",
        type: "saved",
        tur: "formula",
      });
      return isSuccess;
    } else {
      ctx.dispatch("toast", {
        msg: "unsuccessful",
        type: "notSaved",
        tur: "formula",
      });
    }
  },
};
