import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchVisits(ctx) {
      var response = await axios.get(
        `/PlantVisits/GetVisitsByCompanyid/${getUserData().companyid}`
      );
      if (response.data.statusMessage == "OK") return null;

      return response.data.resultdata;
    },
    async fetchVisitsByPlantId(ctx, plantid) {
      var response = await axios.get(
        `/PlantVisits/GetVisitsByPlantid//${plantId}`
      );
      if (response.data.statusMessage == "OK") return null;

      return response.data.resultdata;
    },
    async deleteVisit(ctx, visitid) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Plantvisits/DeletePlantVisit/${visitid}`
            );
            return response.data.status != "OK";
          } else return false;
        });
    },
    async addVisit(ctx, visit) {
      visit.id = 0;
      var response = await axios.post(
        `/PlantVisits/AddPlantVisit/`,
        JSON.stringify(visit)
      );
      return response.data.statusCode == 200;
    },
  },
};
