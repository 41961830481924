import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

function beetween(a, b) {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return this > min && this < max;
}
export default {
  namespaced: true,
  stateFactory: true,
  state: {
    userData: [],
    selectedUser: {},
  },
  getters: {},
  mutations: {
    SET_USERS(state, payload) {
      state.userData = payload;
    },
    SET_USER(state, payload) {
      state.selectedUser = payload;
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      var page = queryParams.perPage * queryParams.page;
      var count = 1;
      var totalCount = 0;
      var offSet = queryParams.perPage * (queryParams.page - 1);
      return new Promise((resolve, reject) => {
        axios
          .get("/Users/GetTinyUsers/", {
            headers: {
              culinfo: getUserData().culinfo,
            },
          })
          .then((response) => {
            ctx.commit("SET_USERS", response.data.resultdata);
            if (response.data.status != "NULL") {
              var res = response.data.resultdata.filter((x) => {
                var isOk = true;
                x.fullname = x.fullname ? x.fullname : "Yeni Kullanıcı";
                x.name = x.name ? x.name : "Yeni Kullanıcı";
                x.surname = x.surname ? x.surname : "Yeni Kullanıcı";

                var fullname = x.fullname
                  .toString()
                  .toLowerCase()
                  .includes(queryParams.q.toLowerCase());
                if (count > offSet && count < page && fullname) {
                  isOk = true;
                } else isOk = false;
                count++;

                return isOk;
              });

              response.data = res;
              response.data.list = count;
            }

            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async deleteUser(ctx, queryParams) {
      var currentUser = getUserData();

      var response = {};
      var response = await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              "/Users/DeleteUser/" + queryParams.id,
              {
                headers: {
                  companyid: currentUser.companyid,
                  useridtoselect: queryParams.id,
                },
              }
            );
            return response;
          } else throw Error("iptal");
        });
      return response;
    },
    async fetchUser(ctx, { id, dispatch }) {
      let user = ctx.state.userData.filter((x) => x.id == id);
      const companyid = getUserData().companyid;
      if (user.length == 0) {
        await ctx.dispatch("fetchUsers", {
          id: companyid,
          q: "",
          perPage: 100,
          page: 100,
        });
        user = ctx.state.userData.filter((x) => x.id == id);
      }
      await axios
        .get("/Plants/GetUsersPlantsWithAll", {
          headers: {
            companyid: companyid,
            useridtoselect: id,
          },
        })
        .then((res) => {
          user[0].idsarray = res.data.resultdata.map((ids) => {
            ids.selected = ids.selected == 1 ? true : false;
            return ids;
          });
        });
      ctx.commit("SET_USER", user[0]);

      return user[0];
    },
    async addUserAsync(ctx, { companyid }) {
      var response = axios.post("Companies/AddUserstoCompany/" + companyid);
    },
    async updateUser(ctx, tinyUser) {
      tinyUser.picturechanged = false;
      tinyUser.ids = "";
      tinyUser.idsarray
        .map((id) => {
          id.selected == 1
            ? (tinyUser.ids = tinyUser.ids + id.id + ",")
            : (tinyUser.ids += "");
        })
        .join();
      var result = await axios.put(
        `/Users/UpdateUser/${tinyUser.id}`,
        tinyUser,
        {
          headers: {
            companyid: getUserData().id,
            useridtoselect: tinyUser.id,
          },
        }
      );
      return result.data.status;
    },
    addUser(ctx, { companyid }) {
      return new Promise((resolve, reject) => {
        var data = 1;
        axios
          .put("Companies/AddUserstoCompany/" + companyid, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
