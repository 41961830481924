import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchCompanyProducts(ctx) {
      var response = await axios.get(`/Ingredients/GetCompanyProducts/`, {});
      if (response.data.status != "OK") return null;

      return response.data.resultdata;
    },

    async deleteCompanyProduct(ctx, ingredientId) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Ingredients/DeleteIngredient/${ingredientId}`
            );
            return response.data.status == "OK";
          } else return false;
        });
    },

    async addCompanyProduct(ctx, newIngredient) {
      var response = await axios.post(
        "/Ingredients/AddIngredient/",
        newIngredient.newIngredient,
        {
          headers: {
            tip: newIngredient.tip == undefined ? "Product" : "",
          },
        }
      );
      return response.data.status == "OK";
    },
    async updateCompanyProduct(ctx, { ingredient, values }) {
      var response = await axios.put(
        `/Ingredients/UpdateIngredient/${ingredient.id}`,
        ingredient
      );
      if (response.data.status == "OK") {
        var result = await axios.put(
          `/Ingredients/UpdateProductDegerler/${ingredient.id}`,
          values
        );
      }
      return result.data.status == "OK";
    },
  },
};
