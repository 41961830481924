import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, queryParams) {
      const { companyid } = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .get("/Orders/GetOrdersByCompanyid/" + companyid)
          .then((response) => {
            return resolve(response.data.resultdata);
          })
          .catch((error) => reject(error));
      });
    },
    async fetchOrderItems(ctx, orderid) {
      var response = await axios.get(`/Orders/GetOrderItems/${orderid}`, {
        headers: { culture: "tr" },
      });
      if (response.data.status != "OK") return null;

      return response.data.resultdata;
    },
    async deleteOrder(ctx, visitid) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            var response = await axios.delete(`/Orders/DeleteOrder/${visitid}`);
            return response.data.status == "OK";
          } else return false;
        });
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
};
