import { getUserData } from "@/auth/utils";
import axios from "@axios";
import store from "@/store";
import VueI18n from "@/libs/i18n";
import { printActions } from "./print";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {
    rationData: [],
    selectedRation: {},
    allIngredients: [],
    allNutrients: [],
    selectedRationIngredients: [],
    selectedRationNutrients: [],
    ingredientLoading: true,
    nutrientLoading: true,
    rationLoading: true,
    cozdu: null,
    rationSaved: false,
  },
  getters: {
    selectedRationModel(state) {
      return state.selectedRation.rasyon[0].model;
    },
  },
  mutations: {
    SET_RATIONS(state, payload) {
      state.rationData = payload;
    },
    SET_RATION(state, payload) {
      state.selectedRation = payload;
    },
    SET_RATION_SAVED(state) {
      state.rationSaved = true;
    },
  },
  actions: {
    //Print Actions
    ...printActions,
    async fetchRations(ctx, { queryParams, tur, plantid }) {
      var page = queryParams.perPage * queryParams.page;
      var count = 1;
      var totalCount = 0;
      var offSet = queryParams.perPage * (queryParams.page - 1);
      let response = await axios.get("/Rations/GetRations/", {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_RATIONS", response.data.resultdata);
      totalCount = response.data.resultdata.length;

      var res = response.data.resultdata.filter((x) => {
        var isOk = true;
        var rationname = x.rationname
          .toString()
          .toLowerCase()
          .includes(queryParams.q.toLowerCase());
        if (count > offSet && count <= page && rationname) {
          isOk = true;
        } else isOk = false;
        count++;

        return isOk;
      });
      response.data = res;
      response.data.list = response.data.length;
      response.data.totalCount = totalCount;
      return response;
    },

    async fetchRation(ctx, { id, tur, plantid }) {
      ctx.state.rationLoading = true;
      ctx.state.nutrientLoading = true;
      ctx.state.ingredientLoading = true;
      let response = await axios.get("/Rations/GetRation/" + id, {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      ctx.state.rationLoading = false;

      if (response.data.status != "OK") return null;
      ctx.commit("SET_RATION", response.data.resultdata[0]);
      return response.data.resultdata[0];
    },
    async deleteRation(ctx, { rationid, plantid, tur }) {
      var response = {};
      var response = await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(`/Rations/DeleteRation/${rationid}`, {
              headers: { plantid, tur },
            });
            return response.data.status != "OK";
          } else return false;
        });
      if (response) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("ration") }),
          },
        });
      }
      return response;
    },
    async postDairyNASEM(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNASEM/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNASEM(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNASEM/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDairyNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDryNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDryRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postHeiferNRCME(ctx, { ration, plantid }) {
      let response = await axios.post(
        "/Rations/AddHeiferRationNRCME/",
        ration,
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async postDairyNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDryNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDryRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postHeiferNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post(
        "/Rations/AddHeiferRationNRCNE/",
        ration,
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async postDairyINRA(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationINRA/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefINRA(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationINRA/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async updateIngredients(ctx) {
      let selectedIngredients = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !ctx.state.selectedRation.hammaddeler.some(
            (element) => element.ikey == ingredient.ikey
          )
      );
      let alreadyIngredients = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return ctx.state.selectedRation.hammaddeler.filter(
              (element) => element.ikey == ingredient.ikey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      ctx.state.selectedRation.hammaddeler = [];
      let hammaddeler = [...alreadyIngredients, ...selectedIngredients];
      ctx.state.selectedRation.hammaddeler = hammaddeler;
    },
    async updateNutrients(ctx) {
      let selectedNutrients = ctx.state.allNutrients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !ctx.state.selectedRation.besinmaddeleri.some(
            (element) => element.nkey == ingredient.nkey
          )
      );
      let alreadyNutrients = ctx.state.allNutrients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return ctx.state.selectedRation.besinmaddeleri.filter(
              (element) => element.nkey == ingredient.nkey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      ctx.state.selectedRation.besinmaddeleri = [];
      let besinmaddeleri = [...alreadyNutrients, ...selectedNutrients];
      ctx.state.selectedRation.besinmaddeleri = besinmaddeleri;

      for (let i = 0; i < ctx.state.selectedRation.besinmaddeleri.length; i++) {
        ctx.state.selectedRation.besinmaddeleri[i].deger =
          ctx.state.selectedRation.besinmaddeleri[i].deger ?? 0;
        ctx.state.selectedRation.besinmaddeleri[i].min =
          ctx.state.selectedRation.besinmaddeleri[i].min ?? 0;
        ctx.state.selectedRation.besinmaddeleri[i].max =
          ctx.state.selectedRation.besinmaddeleri[i].max ?? 0;
      }
    },
    async getRationIngredients(ctx) {
      ctx.state.selectedRationIngredients = [];
      const ids = ctx.state.selectedRation.hammaddeler.map((e) => e.ikey);
      ctx.state.selectedRationIngredients = await ctx.dispatch(
        "getRationIngredientsAll",
        { plantid: store.state.app.selectedPlantId, ids }
      );

      ctx.state.allIngredients = ctx.state.selectedRationIngredients;
    },
    async getRationNutrients(ctx) {
      ctx.state.selectedRationNutrients = [];

      const ids = ctx.state.selectedRation.besinmaddeleri.map((e) => e.nkey);

      ctx.state.selectedRationNutrients = await ctx.dispatch(
        "getRationNutrientsAll",
        { plantid: store.state.app.selectedPlantId, ids }
      );
      ctx.state.allNutrients = ctx.state.selectedRationNutrients;
    },
    async getRationNutrientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Nutrients/GetNutrientsforRation", {
        headers: {
          model: ctx.getters.selectedRationModel,
          culture: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.nutrientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async getRationIngredientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Ingredients/GetIngredientsforRation", {
        headers: {
          model: ctx.getters.selectedRationModel,
          culture: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.ingredientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async solveRationService(ctx, ration) {
      var lastRationBesinMaddeleri = ration.besinmaddeleri.map((e) => {
        return {
          ...e,
          deger: parseFloat(e.deger) == 0 ? 0 : parseFloat(e.deger),
          min: parseFloat(e.min) == 0 ? 0 : parseFloat(e.min),
          max: parseFloat(e.max) == 0 ? 0 : parseFloat(e.max),
        };
      });
      var lastRationHammaddeler = ration.hammaddeler.map((e) => {
        return {
          ...e,
          karisim: parseFloat(e.karisim),
          min: parseFloat(e.min) == 0 ? 0 : parseFloat(e.min),
          max: parseFloat(e.max) == 0 ? 0 : parseFloat(e.max),
        };
      });
      ration.besinmaddeleri = lastRationBesinMaddeleri;
      ration.hammaddeler = lastRationHammaddeler;
      let result = await axios.post(
        "/Rations/SolveRation/",
        JSON.stringify(ration),
        {
          headers: {
            plantid: ration.plantid,
            tur: ration.tur,
          },
        }
      );

      var status = result.data["status"];
      if (status == "OK") {
        return result.data["resultdata"][0];
      } else if (status == "ERROR") {
        var responseRation = result.data["resultdata"][0];
        responseRation.cozdu = false;
        return responseRation;
      } else if (status == "NOFEASIBLE") return result.data["resultdata"][0];

      var responseRation = {};
      responseRation.hammaddeler = ration.hammaddeler;
      responseRation.besinmaddeleri = ration.besinmaddeleri;
      responseRation.cozdu = false;
      return responseRation;
    },
    async saveRationService(ctx, { ration, rationid }) {
      let result = await axios.put(`/Rations/SaveRation/${rationid}`, ration, {
        headers: {
          plantid: ration.plantid,
          tur: ration.tur,
        },
      });
      const status = result.data["status"];
      if (status == "OK") {
        return true;
      } else {
        return false;
      }
    },
    async saveRation(ctx) {
      let _newration = {};
      _newration.plantid = store.state.app.selectedPlantId;
      _newration.price = ctx.state.selectedRation.rasyon[0].fiyat;
      _newration.cozdu = false;
      _newration.minkaba = 0;
      _newration.maxkaba = 0;
      _newration.tur = ctx.state.selectedRation.rasyon[0].tur;
      _newration.yemtoplami = 0;
      _newration.hammaddeler = ctx.state.selectedRation.hammaddeler.map((x) => {
        delete x.id;
        return {
          ikey: x.ikey,
          kod: x.kod,
          ingredientname: x.ingredientname,
          karisim: parseFloat(x.karisim) == 0 ? 0 : parseFloat(x.karisim),
          min: parseFloat(x.min) == 0 ? 0 : parseFloat(x.min),
          max: parseFloat(x.max) == 0 ? 0 : parseFloat(x.max),
          price: parseFloat(x.price) == 0 ? 0 : parseFloat(x.price),
          selected: x.selected,
          product: x.product,
          kodandingredientname: `${x.kod} ${x.ingredientname}`,
          itembgcolor: "green",
          itemtextcolor: "white",
        };
      });
      _newration.besinmaddeleri = ctx.state.selectedRation.besinmaddeleri.map(
        (x) => {
          return {
            id: x.id,
            kod: x.kod,
            nkey: x.nkey,
            orjnkey: x.nkey,
            nutrientname: x.nutrientname,
            birim: x.birim,
            deger: parseFloat(x.deger) == 0 ? 0 : parseFloat(x.deger),
            min: parseFloat(x.min) == 0 ? 0 : parseFloat(x.min),
            max: parseFloat(x.max) == 0 ? 0 : parseFloat(x.max),
            tip: x.tip,
            selected: x.selected,
            kodandnutrientnameandbirim: `${x.kod} ${x.nutrientname} (${x.birim})`,
            itembgcolor: "green",
            itemtextcolor: "white",
          };
        }
      );
      var isSuccess = await ctx.dispatch("saveRationService", {
        ration: _newration,
        rationid: ctx.state.selectedRation.rasyon[0].id,
      });
      if (isSuccess) {
        ctx.commit("SET_RATION_SAVED");

        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("saved", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notSaved", { type: VueI18n.t("ration") }),
          },
        });
      }
    },
    async solveRation(ctx) {
      let tur = ctx.state.selectedRation.rasyon[0].tip;

      const tollerance = 0.00001;

      const isValueValid = (min, max, deger) => {
        if (max === 0) {
          // Eğer max 0 ise, deger herhangi bir degerden büyük olmalı.
          return deger + tollerance > min;
        } else {
          // Max 0 değilse, deger max'dan küçük veya eşit olmalı, min'den büyük olmalı.
          return deger + tollerance > min && deger - tollerance <= max;
        }
      };
      if (
        ctx.state.selectedRation.hammaddeler == null ||
        !ctx.state.selectedRation.hammaddeler.length
      ) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("warning"),
            icon: "AlertCircleIcon",
            variant: "error",
            text: VueI18n.t("oneIngredientNeeded"),
          },
        });
      } else {
        if (
          ctx.state.selectedRation.besinmaddeleri == null ||
          !ctx.state.selectedRation.besinmaddeleri.length
        ) {
          this._vm.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("warning"),
              icon: "AlertCircleIcon",
              variant: "error",
              text: VueI18n.t("oneNutrientNeeded"),
            },
          });
        } else {
          var resultIngredient = false;
          var names = [];
          for (
            var i = 0;
            i < ctx.state.selectedRation.hammaddeler.length;
            i++
          ) {
            if (
              ctx.state.selectedRation.hammaddeler[i].max != 0 &&
              ctx.state.selectedRation.hammaddeler[i].min >
                ctx.state.selectedRation.hammaddeler[i].max
            ) {
              resultIngredient = true;
              names.push(
                ctx.state.selectedRation.hammaddeler[i].ingredientname
              );
            }
          }
          if (resultIngredient) {
            this._vm.$toast({
              component: ToastificationContent,
              props: {
                title: VueI18n.t("warning"),
                icon: "AlertCircleIcon",
                variant: "warning",
                text:
                  VueI18n.t("minValueBiggerThanMax") +
                  "." +
                  VueI18n.t("ingredients") +
                  ": " +
                  names.join(", "),
              },
            });
            return;
          }
          var resultNutrient = false;
          names = [];
          for (
            var i = 0;
            i < ctx.state.selectedRation.besinmaddeleri.length;
            i++
          ) {
            if (
              ctx.state.selectedRation.besinmaddeleri[i].max != 0 &&
              ctx.state.selectedRation.besinmaddeleri[i].min >
                ctx.state.selectedRation.besinmaddeleri[i].max
            ) {
              resultNutrient = true;
              names.push(
                ctx.state.selectedRation.besinmaddeleri[i].nutrientname
              );
            }
          }
          if (resultNutrient) {
            this._vm.$toast({
              component: ToastificationContent,
              props: {
                title: VueI18n.t("warning"),
                icon: "AlertCircleIcon",
                variant: "warning",
                text:
                  VueI18n.t("minValueBiggerThanMax") +
                  "." +
                  VueI18n.t("nutrients") +
                  ": " +
                  names.join(", "),
              },
            });
            return;
          }
          let productexists = false;

          for (
            let i = 0;
            i < ctx.state.selectedRation.hammaddeler.length;
            i++
          ) {
            if (ctx.state.selectedRation.hammaddeler[i].product) {
              productexists = true;
            }
          }
          let forceProduct = getUserData().forceproduct;
          if (tur != "Mix" && forceProduct && !productexists) {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("warning"),
                icon: "AlertCircleIcon",
                variant: "error",
                text: VueI18n.t("oneProductNeeded"),
              },
            });
          } else {
            var tinyRation = {};
            tinyRation.hammaddeler = [];
            tinyRation.besinmaddeleri = [];
            tinyRation.cozdu = false;
            tinyRation.price = 0;
            tinyRation.plantid = store.state.app.selectedPlantId;
            tinyRation.yemtoplami = 0;
            tinyRation.minkaba =
              ctx.state.selectedRation.rasyon[0].kabayemorani;
            tinyRation.maxkaba =
              ctx.state.selectedRation.rasyon[0].kabayemoranimax;

            const parseFloatFixed = (x) => {
              x == ""
                ? parseFloat(0)
                : parseFloat(x.toString().replace(",", "."));
              return x;
            };
            tinyRation.hammaddeler = ctx.state.selectedRation.hammaddeler.map(
              (x) => {
                return {
                  ...x,
                  karisim: parseFloat(x.karisim)
                    .toFixed(getUserData().ingredientdecimal)
                    .replace(".", ","),
                  min: parseFloatFixed(x.min),
                  max: parseFloatFixed(x.max),
                  price: parseFloatFixed(x.price),
                  iskaba: x.iskaba ?? false,
                };
              }
            );

            tinyRation.besinmaddeleri =
              ctx.state.selectedRation.besinmaddeleri.map((x) => {
                return {
                  ...x,
                  deger: parseFloatFixed(x.deger),

                  min: parseFloatFixed(x.min),
                  max: parseFloatFixed(x.max),
                };
              });
            tinyRation.tur = tur;

            let response = await ctx.dispatch("solveRationService", tinyRation);
            tinyRation = response;
            ctx.state.cozdu = tinyRation.cozdu;
            ctx.state.selectedRation.cozdu = tinyRation.cozdu;
            ctx.state.selectedRation.rasyon[0].fiyat = tinyRation.price;
            ctx.state.selectedRation.hammaddeler = tinyRation.hammaddeler;
            ctx.state.selectedRation.besinmaddeleri = tinyRation.besinmaddeleri;

            //Hammaddeler
            for (
              let i = 0;
              i < ctx.state.selectedRation.hammaddeler.length;
              i++
            ) {
              ctx.state.selectedRation.hammaddeler[i].karisim = parseFloat(
                ctx.state.selectedRation.hammaddeler[i].karisim
              );
              ctx.state.selectedRation.hammaddeler[i].min = parseFloat(
                ctx.state.selectedRation.hammaddeler[i].min
              );
              ctx.state.selectedRation.hammaddeler[i].max = parseFloat(
                ctx.state.selectedRation.hammaddeler[i].max
              );
              const min = parseFloat(
                ctx.state.selectedRation.hammaddeler[i].min
              );
              const max = parseFloat(
                ctx.state.selectedRation.hammaddeler[i].max
              );

              const deger = ctx.state.selectedRation.hammaddeler[i].karisim;

              // deger between min and max with tollerance
              if (isValueValid(min, max, deger)) {
                ctx.state.selectedRation.hammaddeler[i].itemtextcolor = "Green";
                ctx.state.selectedRation.hammaddeler[i].itembgcolor = "White";
              } else {
                ctx.state.selectedRation.hammaddeler[i].itemtextcolor = "red";
                ctx.state.selectedRation.hammaddeler[i].itembgcolor = "White";
              }
            }

            for (
              let i = 0;
              i < ctx.state.selectedRation.besinmaddeleri.length;
              i++
            ) {
              ctx.state.selectedRation.besinmaddeleri[i].deger = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].deger ?? 0
              );
              ctx.state.selectedRation.besinmaddeleri[i].min = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].min ?? 0
              );
              ctx.state.selectedRation.besinmaddeleri[i].max = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].max ?? 0
              );

              const min = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].min
              );
              const max = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].max
              );
              const deger = parseFloat(
                ctx.state.selectedRation.besinmaddeleri[i].deger ?? 0
              );

              if (isValueValid(min, max, deger)) {
                ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
                  "Green";
                ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
                  "White";
              } else {
                ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
                  "red";
                ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
                  "White";
              }

              // if (
              //   (ctx.state.selectedRation.besinmaddeleri[i].max != 0 &&
              //     (ctx.state.selectedRation.besinmaddeleri[i].deger >
              //       ctx.state.selectedRation.besinmaddeleri[i].min ||
              //       Math.abs(
              //         ctx.state.selectedRation.besinmaddeleri[i].deger -
              //           ctx.state.selectedRation.besinmaddeleri[i].min
              //       ) < tollerance) &&
              //     ctx.state.selectedRation.besinmaddeleri[i].deger <
              //       ctx.state.selectedRation.besinmaddeleri[i].max) ||
              //   Math.abs(
              //     ctx.state.selectedRation.besinmaddeleri[i].deger -
              //       ctx.state.selectedRation.besinmaddeleri[i].max
              //   ) < tollerance ||
              //   (ctx.state.selectedRation.besinmaddeleri[i].max == 0 &&
              //     (ctx.state.selectedRation.besinmaddeleri[i].deger >
              //       ctx.state.selectedRation.besinmaddeleri[i].min ||
              //       Math.abs(
              //         ctx.state.selectedRation.besinmaddeleri[i].deger -
              //           ctx.state.selectedRation.besinmaddeleri[i].min
              //       ) < tollerance))
              // ) {
              //   ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
              //     "Green";
              //   ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
              //     "White";
              // } else {
              //   ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
              //     "Red";
              //   ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
              //     "White";
              // }
              if (
                ctx.state.selectedRation.besinmaddeleri[i].nkey == 19 &&
                store.state.app.selectedModelId == 2
              ) {
                ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor = "";
                ctx.state.selectedRation.besinmaddeleri[i].itembgcolor = "";
              }
            }
          }

          if (ctx.state.selectedRation.cozdu) {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("successful"),
                icon: "AlertCircleIcon",
                variant: "success",
                text: VueI18n.t("solved"),
              },
            });
          } else {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("unsuccessful"),
                icon: "AlertCircleIcon",
                variant: "danger",
                text: VueI18n.t("notSolved"),
              },
            });
          }
        }
      }
    },
  },
};
