import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Companies/GetCompanies")
          .then((response) => {
            var res = response.data.filter((x) =>
              x.companyName.toString().includes(queryParams.q)
            );
            response.data = res;
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCompany(ctx, queryParams) {
      return new Promise(async (resolve, reject) => {
        var response = {};
        return await this._vm
          .$swal({
            title: VueI18n.t("areYouSure"),
            text: VueI18n.t("notRevert"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: VueI18n.t("yes"),
            cancelButtonText: VueI18n.t("no"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          })
          .then(async (result) => {
            if (result.value) {
              response = await axios
                .delete("/Companies/Delete/" + queryParams.id)
                .catch((error) => reject(error));
              return response.data.status != "OK";
            } else return false;
          });
      });
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Companies/GetCompanyByid/${id}`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCompanyModules(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Companies/GetCompanyModules/${id}`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCompanyAnimalCount(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Companies/CompanyAnimalCounts/`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Companies/AddCompany/", companyData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getModules(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Modules/GetModules")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
