import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {
    saleItems: [],
    userSaleItems: [],
    basket: [],
    renewBasket: {},
    iFrameToken: "",
  },
  mutations: {
    SET_SALE_ITEMS(state, saleItems) {
      state.saleItems = saleItems;
    },
    SET_USER_SALE_ITEMS(state, userSaleItems) {
      state.userSaleItems = userSaleItems;
    },
    SET_BASKET(state, basket) {
      state.basket = basket;
    },
    SET_RENEW_BASKET(state, renewBasket) {
      state.renewBasket = renewBasket;
    },
    SET_TOKEN(state, token) {
      state.iFrameToken = token;
    },
  },
  actions: {
    async getSaleItems({ commit }) {
      const { data } = await axios.get("/Sales/GetSaleItems");

      var baseItems = data.resultdata
        .filter((item) => item.isbase && !item.iswindows)
        .map((item) => ({
          ...item,
          totalPrice: "0",
          pictureUrl: "",
        }));
      baseItems = [...baseItems];

      var extraPackages = [
        ...data.resultdata.filter((item) => !item.isbase).map((x) => x),
      ];
      for (let index = 0; index < baseItems.length; index++) {
        baseItems[index].extraPackages = [
          ...extraPackages.map((x) => ({
            ...x,
            quantity: 0,
          })),
        ];
        (index) => {
          const baseItemPrice =
            baseItems[index][
              "priceper" + (index % 2) == 0 ? "year" : "month"
            ].toString();
          const extraPackagePrice = baseItems[index].extraPackages
            .filter((x) => x.quantity != 0)
            .reduce((total, extraPackage) => {
              return (
                total +
                extraPackage["priceper" + (index % 2) == 0 ? "year" : "month"] *
                  extraPackage.quantity
              );
            }, 0);
          baseItems[index].totalPrice = (
            parseFloat(baseItemPrice) + parseFloat(extraPackagePrice)
          ).toFixed(2);
        };
      }

      let items = [
        //   JSON.parse(JSON.stringify(baseItems[0])),
        //   JSON.parse(JSON.stringify(baseItems[0])),
      ];

      for (let i = 0; i < baseItems.length; i++) {
        items.push(JSON.parse(JSON.stringify(baseItems[i])));
        items.push(JSON.parse(JSON.stringify(baseItems[i])));
      }

      commit("SET_SALE_ITEMS", items);
      return items;
    },
    async addBasketItem({ commit, state, dispatch }, item) {
      var basket = { ...state.basket };
      if (item.saleitemid == undefined) item.saleitemid = item.id;
      if (
        basket.items &&
        basket.items.length > 0 &&
        basket.items.find((x) => x.saleitemid == item.saleitemid)
      ) {
        if (
          basket.items.find((x) => x.saleitemid == item.saleitemid).quantity ==
          undefined
        )
          basket.items.find(
            (x) => x.saleitemid == item.saleitemid
          ).quantity = 0;
        basket.items.find((x) => x.saleitemid == item.saleitemid).quantity++;
      } else {
        basket.items.push(item);
      }
      commit("SET_BASKET", basket);
      await dispatch("saveUserBasket");
    },
    async getUserSaleItems({ commit }) {
      const { data } = await axios.get("/Sales/GetSaleItemsForUser");
      var baseItems = data.resultdata
        .map((item) => ({
          ...item,
          totalPrice: "0",
          pictureUrl: item.saleitemname.toLowerCase().includes("multi")
            ? ""
            : "",
        }))
        .filter((x) => !x.iswindows);
      commit("SET_USER_SALE_ITEMS", baseItems);
      return baseItems;
    },

    async saveUserBasket({ commit, state }, basket) {
      let postData = [
        ...state.basket.items.map((x) => ({
          saleitemid: x.saleitemid,
          quantity: x.quantity ?? 1,
          pricetype: 2,
          orjprice: x.orjprice,
          culinfo: VueI18n.locale ?? "tr",
        })),
      ];
      if (basket != undefined) postData = [...basket];
      const { data } = await axios.post("/Baskets/SaveUserBasket", {
        basket: postData,
      });

      localStorage.setItem("basket", JSON.stringify(data.resultdata));
      return data.resultdata;
    },
    async getUserBasket({ commit }, userId) {
      const basket = localStorage.getItem("basket");
      const { data } = await axios.get(`/Baskets/GetUserBasket/${userId}`, {
        basket: JSON.parse(basket),
      });
      if (data.status != "ERROR") {
        localStorage.setItem("basket", JSON.stringify(data.resultdata[0]));
        commit("SET_BASKET", data.resultdata[0]);
      } else {
        localStorage.setItem(
          "basket",
          JSON.stringify({ basket: [], items: [] })
        );
        commit("SET_BASKET", { basket: [], items: [] });
      }
    },
    async getUserRenewBasket({ commit }, pricetype) {
      const {
        data: { resultdata },
      } = await axios.get("/Baskets/GetUserRenewBasket");
      commit("SET_RENEW_BASKET", resultdata);
      return resultdata;
    },
    async getIframeToken({ commit, dispatch }) {
      const {
        data: { resultdata },
      } = await axios.get("/Sales/GetIframeToken");
      commit("SET_TOKEN", statusdata);
      dispatch("getUserBasket");
      return resultdata;
    },
    async getNewPaymentIframeToken({ commit }) {
      const {
        data: { statusdata },
      } = await axios.get("/Payment/getrenewiframetoken", {
        headers: {
          pricetype: pricetype,
        },
      });
      commit("SET_TOKEN", statusdata);
      dispatch("getUserBasket");
      return resultdata;
    },
  },
};
