import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchModules(ctx) {
      var companyid = getUserData() == null ? 1 : getUserData().companyid;
      var response = await axios.get(
        `/Companies/GetCompanyModules/${companyid}`
      );
      return response.data.resultdata.sort((a, b) =>
        a.expired < b.expired ? -1 : 1
      );
    },
    async updateModuleUser(ctx, { userid, moduleid }) {
      var result = await axios.put(
        `/Companies/UpdateModuleUser/${moduleid}`,
        JSON.stringify(userid)
      );
      return result.statusCode;
    },
  },
};
